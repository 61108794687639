/* eslint-disable */
import { Component, ChangeDetectorRef, ViewChild, Input, ViewContainerRef } from '@angular/core';
import {
    AppBridge, NovoModalParams, NovoModalRef, IDataTableColumn, NovoSelectElement, NovoIconModule, NovoToastService

} from 'novo-elements';
// import { NovoAsideService } form 'novo-elements/aside';
// import ngRepeatStart
//app
import { HttpService } from '../services/http.service';
import { SearchComponent } from '../search/search.component';
import { BasicPicker } from '../basic-picker/basic-picker';
import { FormattedChips } from '../chips/formatted-chips';
import { LongSelect } from '../long-select/long-select';
import { AppBridgeService } from '../tools/service/app-bridge.service';
import { SearchResponse } from '../interfaces/bullhorn';
import { Field, Operator, Chip, ChipSelection, AreaSelection, Selection, Criteria, isAreaSelection, isDateSelection, isChipSelection, DateSelection } from '../tools/tools.types';
import axios from 'axios';
import { format, addDays, addYears, startOfDay, endOfDay, min, max } from 'date-fns';

@Component({
    selector: 'binocular-model',
    templateUrl: './binocular-model.component.html',
    styleUrls: ['./binocular-model.component.scss'],
})
export class BinocularModelComponent {
    selectedValues: any;
    changedValues = [];
    uniquearray = [];
    AllData = [];
    fieldsArr = [];
    public options = [];
    stateData = [];
    selectedId = '';
    candidateName = '';
    mergeAll = false;
    entityType = '';
    message = '';
    binocularModel = false;
    searchModel = false;
    binocularResumeModel = false;
    modaltype = 0;
    mergeAllWarning = '';
    jobOrderId = '';
    candidateId = '';
    fileId = '';
    fileName = '';
    i = 1;
    k = 0;
    addedR = '';
    isAddress = false;
    isRedius = false;
    testAr = [];

    public columns: any;

    public value: string;
    public Splaceholder: string = 'Select..';
    public SstaticDemo: any = [];
    public Svalue: any = [];
    public SonChanged: any;
    public SinputSearch: Array<string> = [];

    public Cplaceholder: string = 'Select..';
    public Cformatted: any;
    public Cvalue: any = [];
    public ConChanged: any;
    public states: any;
    public state: any = [];
    public sels: any = [];
    public sel: any = [];
    public operators: Array<Operator> = [];
    public pastDateFields = ['dateAdded', 'dateI9Expiration', 'dateLastModified', 'dateNextCall', 'dateLastComment', 'userDateAdded'];
    public withinSearch: Array<string> = [];
    public withinOptions: any = [
      { label: 'Future', value: Infinity, exclude: true },
      { label: 'Past 1 Day', value: -1 },
      { label: 'Past 7 Days', value: -7 },
      { label: 'Past 14 Days', value: -14 },
      { label: 'Past 21 Days', value: -21 },
      { label: 'Past 30 Days', value: -30 },
      { label: 'Past 60 Days', value: -60 },
      { label: 'Past 90 Days', value: -90 },
      { label: 'Past 180 Days', value: -180 },
      { label: 'Past 270 Days', value: -270 },
      { label: 'Past 1 Year', value: -365 },
      { label: 'Next 1 Day', value: 1, exclude: true },
      { label: 'Next 7 Days', value: 7, exclude: true },
      { label: 'Next 14 Days', value: 14, exclude: true },
      { label: 'Next 21 Days', value: 21, exclude: true  },
      { label: 'Next 30 Days', value: 30, exclude: true  },
      { label: 'Next 60 Days', value: 60, exclude: true  },
      { label: 'Next 90 Days', value: 90, exclude: true  },
      { label: 'Next 180 Days', value: 180, exclude: true  },
      { label: 'Next 270 Days', value: 270, exclude: true  },
      { label: 'Next 1 Year', value: 365, exclude: true  },
    ];
    public activeDate: Date = new Date();
    public selectedDate: any = [];

    criteria: Criteria = [];
    recentSearches: Array<{ criteria: Criteria, keywords: string, date: Date }> = [];
    public updateSearchFields: any;
    public keyWords: string = "";
    public selectionOptions = {};
    public chipInputValues: Array<string> = ['']; 
    locationSearchTimeout: any;
    zipCode: any;
    calendarsOpen = {};
    today = format(new Date(), "MMMM DD, YYYY");

    constructor(
        private appBridgeService: AppBridgeService,
        private modalRef: NovoModalRef,
        private params: NovoModalParams,
        private ref: ChangeDetectorRef,
        private httpService: HttpService,
        public searchComponent: SearchComponent,
        private basicPicker: BasicPicker,
        private formattedChips: FormattedChips,
        private longSelect: LongSelect,
        private icons: NovoIconModule,
        private toaster: NovoToastService,
        private vcRef: ViewContainerRef
    ) {
        this.toaster.parentViewContainer = vcRef;
        this.SstaticDemo[0] = [];
        // this.sel = [];
        // this.SstaticDemo[0] = basicPicker.formatted;
        // this.Svalue = basicPicker.value;
        this.SonChanged = basicPicker.onChanged;
        // this.testAr = [1, 2, 3, 4];
        this.Cformatted = formattedChips.formatted;
        // this.Cvalue = formattedChips.value;
        this.ConChanged = formattedChips.onChanged;
        //this.states = longSelect.states;
        if (this.isAddress) {
            this.states = {
                field: 'name',
                format: `$label`,
                options: [
                    { name: 'INA', label: 'Include All', },
                    { name: 'INAY', label: 'Include Any', },
                    { name: 'EXC', label: 'Exclude All', },
                    { name: 'REDIUS', label: 'Redius', }

                ],
            };
        } else {
            this.states = {
                field: 'name',
                format: `$label`,
                options: [
                    { name: 'INA', label: 'Include All', },
                    { name: 'INAY', label: 'Include Any', },
                    { name: 'EXC', label: 'Exclude All', },
                ],
            };
        }
        //   this.state = [
        //     { label: 'Include All', value: { id: 'INA', label: 'Include All' } },
        //     { label: 'Include Any', value: { id: 'INAY', label: 'Include Any' } },
        //     { label: 'Exclude All', value: { id: 'EXC', label: 'Exclude All' } },
        //     { label: 'Redius', value: { id: 'REDIUS', label: 'Redius' } }

        //   ];
        // this.state = longSelect.state;
        this.sels = {
            field: 'name',
            format: `$label`,
            options: [
                { label: '5 miles', name: 5 },
                { label: '10 miles', name: 10 },
                { label: '20 miles', name: 20 },
                { label: '30 miles', name: 30 },
                { label: '40 miles', name: 40 },
                { label: '50 miles', name: 50 },
                { label: '100 miles', name: 100 },
            ]
        };
        // this.sels = longSelect.sels;
        //this.sel = longSelect.sel;
    } //

    ngOnInit() { // 
        //console.log('confirmation params =====>>>>>> ', this.params);
        this.selectedValues = this.params['selectedValues'];
        this.entityType = this.params['entityType'];
        this.criteria = this.params['criteria'];
        this.keyWords = this.params['keywords'];
        this.recentSearches = this.params['recentSearches'];
        this.updateSearchFields = this.params['updateSearchFields'];
        this.operators = this.params['operators'];
        if (this.entityType == "binocularmodelpopup") {
            this.binocularModel = true;
            this.AllData = this.selectedValues;
            this.candidateName = `${this.selectedValues.firstName} ${this.selectedValues.lastName}`;
            this.selectedId = this.selectedValues.id;
            //console.log("DATA:", this.selectedId);
            this.jobOrderId = this.selectedValues.jobOrderId;
            this.candidateId = this.selectedValues.candidateId;
            this.fileId = this.selectedValues.fileId;
            // console.log("JOB ID:", this.jobOrderId);
            // console.log("CANDIDATE ID:", this.candidateId);
            // console.log("DATA: ", this.selectedValues);

            // console.log("FILE ID:", this.fileId);
        }
        if (this.entityType == "searchmodelpopup") {
            this.searchModel = true;
            this.AllData = this.selectedValues;
            this.loadOptions();
            this.testAr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
            // this.basicPicker.loadData();
            // this.SstaticDemo = this.basicPicker.formatted;

            // this.Svalue = this.basicPicker.getvalue();
        }
        
    }
    keyFunc(val) {
        // console.log(val)
        this.Cformatted = {
            format: '$firstName',
            options: [{
                id: val,
                firstName: val
            }],
        };
    }
    lonChanged(event, a) {
        // console.log('EVENT', event); // tslint:disable-line
        //alert(this.value)
        console.log('EVENT a', a);
        let sts = '';
        let arrayk: Array<any> = event.value;
        arrayk.forEach(element => {
            sts += element.id + ',';
        });
        this.stateData[a] = sts;
        // console.log('EVENT value', this.stateData);

    }
    public actionID(type: any, data: any,): void {
        // console.log('Event Triggered!', type, data,); // tslint:disable-line

        if (type != '') {
            let ids = data.row.id;
            data.row.StatusValue = type;
        }
    }
    public loadOptions() {
        this.httpService.loadOptions().then((res: any) => {
            if (!res || !res.fields) return;
            let availableFields: Array<string> = ["address", "companyName", "salary", "desiredLocations", "phone", "email", "email2", "firstName", "businessSectors", "lastName", "customText2", "companyURL", "customTextBlock3", "middleName", "mobile", "name", "nickName", "phone2", "owner", "comments", "customText6", "description", "customTextBlock1", "status", "occupation", "willRelocate", "workPhone", "experience"];
            for (let field of res.fields) {
              if (field.hideFromSearch || field.label.includes('(Field is Not Searchable)') || field.name === 'id') continue;
              this.options.push({ name: field.name, label: field.label, optionsUrl: field.optionsUrl, isAvailable: availableFields.includes(field.name), dataType: field.dataType });
              if (field.options) this.selectionOptions[field.name] = field.options;
            }
            this.options.sort((a, b) => a.label.localeCompare(b.label))

            // for (let i = 0; i < res.fields.length; i++) {
            //     //console.log(res.fields[i].label);
            //     if (i != 0 || res.fields[i].hideFromSearch == false)
            //         if (!res.fields[i].label.includes('(Field is Not Searchable)'))
            //             this.options.push({ 'name': res.fields[i].name, 'label': res.fields[i].label });
            // }
            let timestampFields = this.options.filter(option => option.dataType === 'Timestamp');
            this.SstaticDemo[0] = {
                field: 'name',
                format: `$label`,
                options: this.options,
            };
            // this.options = res.fields;
            // console.log('Options', this.options);
        });
    }
    trackByFn(ic) {
        // console.log(ic)
        for (let j = 1; j < ic; j++) {
            this.SstaticDemo[j] = {
                field: 'name',
                format: `$label`,
                options: this.options,
            };
        }
        // console.log('SstaticDemo',this.SstaticDemo);

    }
    close(searchArr, confirmed) {

        if (confirmed == false) { searchArr = ""; }
        // if (confirmed == false) { this.value = ""; }
        this.modalRef.close(searchArr);
        
    }
    addFields() {
        //alert(this.i)
        var addedR = `<div class="searchInputForm" id="searchInputForm${this.i}" style="display: flex;
        flex-flow: row wrap;
        align-items: center;
        width: 100%;">
        <div class="subDiv" style="width: 5%;display: inline-block;
        padding: auto;
        margin-right: 15px;
        height: inherit;">
            <a style="cursor: pointer; color:red" onclick="hideRow(${this.i})">X</a>
        </div>
        <div class="subDiv" style="display: inline-block;
        width: 20%;
        padding: auto;
        margin-right: 15px;
        height: inherit;">
            <input style="vertical-align: middle;
            margin: 5px 10px 5px 0;
            padding: 10px;
            width:80%;
            background-color: #fff;
            border: 1px solid #1e1e1e90;" type="text" id="add${this.i}" placeholder="Address">
        </div>
        <div class="subDiv" style="display: inline-block;
        width: 20%;
        padding: auto;
        margin-right: 15px;
        height: inherit;">
            <select id="sel${this.i}" style="vertical-align: middle;
            margin: 5px 10px 5px 20px;
            padding: 10px;
            width: 80%;
            background-color: #fff;
            border: 1px solid #1e1e1e90;">
                <option>Radius</option>
            </select>
        </div>
        <div class="subDiv" style="display: inline-block;
        width: 20%;
        padding: auto;
        margin-right: 15px;
        height: inherit;">
            <select id="selArea${this.i}" style="vertical-align: middle;
            margin: 5px 10px 5px 20px;
            padding: 10px;
            width: 80%;
            background-color: #fff;
            border: 1px solid #1e1e1e90;">
                <option value="5">5 miles</option>
                <option value="10" selected>10 miles</option>
                <option value="20">20 miles</option>
                <option value="50">50 miles</option>
                <option value="100">100 miles</option>
            </select>
        </div>
        <div class="subDiv" style="display: inline-block;
        width: 20%;
        padding: auto;
        margin-right: 15px;
        height: inherit;">
            <input style="vertical-align: middle;
            margin: 5px 10px 5px 0;
            padding: 10px;
            width:80%;
            background-color: #fff;
            border: 1px solid #1e1e1e90;" type="text" id="state${this.i}" placeholder="State and Country">
        </div>
    </div>`;
        this.i++;
        document.getElementById("addedrow").innerHTML += addedR;
    }
    hideRow(index: number) {
        //alert(k)
        // document.getElementById("searchInputForm" + k).style.display = "none";
        this.criteria.splice(index, 1);
    }
    inputChange(i, val) {
        // console.log(i, val)
        let field = (document.getElementById("add" + i) as HTMLInputElement).getAttribute("ng-reflect-model")
        // console.log(field)
        if (field == 'address') {
            this.isAddress = true;
            this.states = {
                field: 'name',
                format: `$label`,
                options: [
                    { name: 'INA', label: 'Include All', },
                    { name: 'INAY', label: 'Include Any', },
                    { name: 'EXC', label: 'Exclude All', },
                    { name: 'REDIUS', label: 'Redius', }

                ],
            };
        } else {
            this.isAddress = false;
            this.states = {
                field: 'name',
                format: `$label`,
                options: [
                    { name: 'INA', label: 'Include All', },
                    { name: 'INAY', label: 'Include Any', },
                    { name: 'EXC', label: 'Exclude All', },

                ],
            };
        }

    }
    stChange(a) {
        // console.log('gbcfb', a)
        let field = (document.getElementById("sel" + a) as HTMLInputElement).getAttribute("ng-reflect-model")
        // console.log('gbcfb field', field)
        if (field == 'REDIUS') {
            this.isRedius = true;

        } else {
            this.isRedius = false;

        }

    }
    counter(a: number) {
        return new Array(a);
    }
    add_Fields() {
        this.i++
    }
    addCriteria() {
      this.criteria.push({ field: { name: "", label: "" }, operator: { ...this.operators[0] }, selection: [] })
    }
    getValue(index: number, property: string, event: Event) {
      this[property][index] = (event.target as HTMLInputElement).value;
      this.updateSearch();
    }
    setValue(index: number, property: string, field: Field) {
      this.criteria[index][property] = field;
      if (property === 'field') {
        this.getSelectionOptions(field);
        this.SinputSearch[index] = '';
        let possibleOperators = this.getOperators(field);
        let currentOperator = possibleOperators.find((operator) => operator.name === this.criteria[index]?.operator?.name);
        if (possibleOperators.slice(-1)[0].name === 'RADIUS') {
          possibleOperators.reverse();
          this.criteria[index].operator = { ...possibleOperators[0] };
        } else if (!currentOperator) {
          this.criteria[index].operator = { ...possibleOperators[0] };
        }
        if (field.dataType === 'Timestamp') {
          this.criteria[index].selection = { startDate: [new Date()], startPreview: '', endDate: [new Date()], endPreview: '' };
        } else {
          this.criteria[index].selection = [];
        }
      }
      if (property === 'operator') {
        if (field.name === 'RADIUS') this.criteria[index].selection = { radius: 0, location: "" };
        else if ((field as Operator).dataTypes?.includes('Timestamp')) this.criteria[index].selection = { startDate: [new Date()], startPreview: '', endDate: [new Date()], endPreview: '' };
        else if (isAreaSelection(this.criteria[index].selection)) this.criteria[index].selection = [];
      }
      this.updateSearch();
    }
    addChip(index: number, chip: Chip | string) {
      if (typeof chip === 'string') chip = { label: chip, value: chip };
      (this.criteria[index].selection as ChipSelection).push(chip);
      this.chipInputValues[index] = '';
    }
    removeChip(index: number, chip: Chip) {
      let chipIndex = (this.criteria[index].selection as ChipSelection).findIndex(existingChip => existingChip.value === chip.value);
      if (chipIndex !== -1) {
        (this.criteria[index].selection as ChipSelection).splice(chipIndex, 1);
      }
    }
    setRadius(index: number, radius: number) {
      (this.criteria[index].selection as AreaSelection).radius = radius;
    }
    getRadiusLabel(radius: number) {
      return this.sels.options.find(selection => parseInt(selection.name) === radius)?.label || '';
    }
    async setLocation(index: number, location: { label: string, value: { lat: number, lng: number } }) {
      (this.criteria[index].selection as AreaSelection).location = location.label;
      this.zipCode = await this.getZipCodeByLatLng(location.value);
    }
    getLocation(index: number, event: Event) {
      let target = (event.target as HTMLInputElement);
      (this.criteria[index].selection as AreaSelection).location = target.value;
    }
    getSelectionOptions(field: Field) {
      if (this.selectionOptions[field.name] || !field.optionsUrl) return;
      
      this.appBridgeService.execute(async (appBridge: AppBridge) => {
        let params = field.optionsUrl.split('/').pop();
        const searchResponse: { data: SearchResponse, error: any } = await appBridge.httpGET(`options/${params}`);
        this.selectionOptions[field.name] = searchResponse.data.data;
      });
    }
    toggleClass(className: string, event: Event, closest: string, shouldAdd?: boolean) {
      let target = (event.target as HTMLInputElement).closest(closest);
      if (shouldAdd) {
        target.classList.add(className)
      } else if (shouldAdd === false) {
        target.classList.remove(className);
      } else {
        target.classList.toggle(className);
      }
    }
    clearSelections(index: number) {
      if (this.criteria[index].operator?.name == 'RADIUS') this.criteria[index].selection = { radius: 0, location: "" };
      else if (this.criteria[index].operator?.dataTypes?.includes("Timestamp")) this.criteria[index].selection = { startDate: [new Date()], startPreview: '', endDate: [new Date()], endPreview: '' }
      else this.criteria[index].selection = [];
    }
    shouldShow(option, searchValue = '', index) {
      if (option.exclude && this.pastDateFields.includes(this.criteria[index].field.name)) return false;
      let matchesSearch =  option.label.toLowerCase().includes(searchValue.toLowerCase());
      let addressAlreadyExists = option.name === 'address' && this.criteria.find(criteria => criteria.field?.name === 'address');  
      return matchesSearch && !addressAlreadyExists;
    }
    formatRecentSearch(recentSearch) {
      if (recentSearch.length < 1 || (recentSearch.length === 1 && recentSearch[0].selection.length === 0)) return ""
      return `Additional Criteria: ${recentSearch.map(criteria => criteria.field.label).join(', ')}`;
    }
    formatDate(date) {
      return date.toLocaleString('en-us', { dateStyle: 'short', timeStyle: "short" });
    }
    clearForm() {
      this.keyWords = '';
      this.criteria.forEach((criteria, index) => this.clearSelections(index));
      this.updateSearchFields(this.keyWords, this.criteria);
    }
    updateSearch() {
      this.updateSearchFields(this.keyWords, this.criteria);
    }
    getOperators(field) {
      let possibleOperators = [];
      for (let operator of this.operators) {
        if (this.isValidOperator(field, operator)) possibleOperators.push(operator);
      }
      return possibleOperators;
    }
    isValidOperator(field, operator) {
      if (!field) return false;
      if (operator.dataTypes?.includes(field.dataType)) return true;
      if (field.dataType === 'Timestamp') return false;
      if (!operator.dataTypes) return true;
      return false;
    }
    async findCities(searchString) {
      if (!searchString) return [];
      let url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(searchString.trim())}&key=AIzaSyCkrQZPtfZoTIq-uJQoi-VN_sAjzJ0lwJM`;
      try {
        let { data: { results } } = await axios.get(url);
        return results;
      } catch (err) {
        console.log(err);
        return [];
      }
    }

    handleLocationChange(index, event) {
      clearTimeout(this.locationSearchTimeout);
      let searchString = event.target.value;
      (this.criteria[index].selection as AreaSelection).location = searchString;
      this.zipCode = null;
      this.selectionOptions['address'] = [];
      if (!searchString) return;
      this.locationSearchTimeout = setTimeout(async () => {
        let cities = await this.findCities(searchString);
        this.selectionOptions['address'] = cities.map(city => {
          return { label: city.formatted_address, value: city.geometry.location };
        });
      }, 1000);
    }
    handleWithinChange(index, option) {
      let today = new Date();
      let startDate, endDate, tempDate;

      if (option.value === Infinity) {
        startDate = today;
        endDate = new Date('12/31/2099');
      } else {
        if (Math.abs(option.value) === 365) {
          tempDate = addYears(new Date(), Math.sign(option.value));
        } else {
          tempDate = addDays(new Date(), option.value);
        }
        startDate = min(tempDate, today);
        endDate = max(tempDate, today);
      }
      (this.criteria[index].selection as DateSelection).startDate = [startDate];
      (this.criteria[index].selection as DateSelection).startPreview = new Intl.DateTimeFormat('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(startDate);
      (this.criteria[index].selection as DateSelection).endDate = [endDate];
      (this.criteria[index].selection as DateSelection).endPreview = new Intl.DateTimeFormat('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(endDate);
      (this.criteria[index].selection as DateSelection).label = option.label;
    }

    handleDateChange(index, event, isFirst) {
      let date = new Date(event.target.value);
      if (isNaN(date.valueOf())) {
        if (isFirst) (this.criteria[index].selection as DateSelection).startError = "Please use MM/DD/YYYY format";
        else (this.criteria[index].selection as DateSelection).endError = "Please use MM/DD/YYYY format";
        return;
      }
      this.setDateSelection(index, event[0], isFirst);
    }
    handleCalendarChange(index, event, isFirst) {
      this.setDateSelection(index, event[0], isFirst);
    }
    setDateSelection(index, date, isFirst = true) {
      let operator = this.criteria[index].operator;
      let startDate = new Date('01/01/1900');
      let endDate = new Date();
      switch (operator.name) {
        case 'BEFORE':
          startDate = new Date('01/01/1900');
          endDate = date;
          break;
        case 'AFTER':
          startDate = date;
          endDate = new Date('12/31/2099');
          break;
        case 'EQUALS':
          startDate = new Date(date);
          startDate.setHours(0, 0, 0);
          endDate = new Date(date);
          endDate.setHours(23, 59, 59);
          break;
        case 'BETWEEN':
          if (isFirst) {
            startDate = date;
            endDate = (this.criteria[index].selection as DateSelection).endDate[0];
          } else {
            startDate = (this.criteria[index].selection as DateSelection).startDate[0];
            endDate = date;
          }
          break;
      }
      (this.criteria[index].selection as DateSelection).startDate = [startDate];
      (this.criteria[index].selection as DateSelection).startPreview = new Intl.DateTimeFormat('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(startDate);
      (this.criteria[index].selection as DateSelection).endDate = [endDate];
      (this.criteria[index].selection as DateSelection).endPreview = new Intl.DateTimeFormat('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(endDate);
      this.toggleCalendar(index, isFirst, false);
      if (isFirst) (this.criteria[index].selection as DateSelection).startError = '';
      else (this.criteria[index].selection as DateSelection).endError = '';
    }
    toggleCalendar(index, isFirst, value) {
      if (!this.calendarsOpen[index]) this.calendarsOpen[index] = { first: false, second: false };
      if (isFirst) this.calendarsOpen[index].first = value ?? !this.calendarsOpen[index].first;
      else this.calendarsOpen[index].second = value ?? !this.calendarsOpen[index].second;
    }




    // public search() {
    //     //alert('search')
    //     let searchArr_keyWord = (document.getElementById("keyWord") as HTMLInputElement).value;
    //     let searchArr = [];
    //     let searchArr_AdditionalFields = [];

    //     // console.log('ng-reflect-model', (document.getElementById("add0") as HTMLInputElement).getAttribute("ng-reflect-model"));
    //     console.log('searchArr', searchArr);
    //     for (let j = 0; j < this.i; j++) {
    //         let field = (document.getElementById("add" + j) as HTMLInputElement).getAttribute("ng-reflect-model")
    //         let sel = (document.getElementById("sel" + j) as HTMLInputElement).getAttribute("ng-reflect-model")
    //         let selArea = '';
    //         let state = '';
    //         if (sel == "REDIUS") {
    //             selArea = (document.getElementById("selArea" + j) as HTMLInputElement).getAttribute("ng-reflect-model")
    //             state = (document.getElementById("state" + j) as HTMLInputElement).value
    //         } else {
    //             state = this.stateData[j]
    //         }


    //         searchArr_AdditionalFields.push({ 'field': field, 'sel': sel, 'selArea': selArea, 'state': state });

    //     }

    //     // console.log(searchArr_keyWord);
    //     // console.log(searchArr_AdditionalFields);
    //     searchArr.push({
    //         'keyWord': searchArr_keyWord,
    //         'additionalFields': searchArr_AdditionalFields
    //     });
    //     console.log('searchArr', searchArr);
    //     this.close(searchArr, true);
    //     // this.searchComponent.searchProcess(searchArr);
    //     // this.httpService.searchProcess(searchArr).then((res:any)=>{
    //     //     console.log('result', res);

    //     // });


    // }
    
    async getZipCodeByLatLng(position) {
      try {
        let resp = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.lat},${position.lng}&key=AIzaSyCkrQZPtfZoTIq-uJQoi-VN_sAjzJ0lwJM`);
        let zipCode;
        for (let location of resp.data.results) {
          if (!location.types.includes('postal_code')) continue;
          for (let addressComponent of location.address_components) {
            if (!addressComponent.types.includes('postal_code')) continue;
            zipCode = addressComponent.long_name;
            break;
          }
        }
        return zipCode;
      } catch (err) {
        console.log(err);
        return;
      }
    }
    
    public saveSearch() {
      let existingSearches = localStorage.getItem('recentSearches');
      this.recentSearches = existingSearches ? JSON.parse(existingSearches) : [];
      this.recentSearches.unshift({ criteria: this.criteria, keywords: this.keyWords, date: new Date() });
      if (this.recentSearches.length > 10) this.recentSearches.pop();
      localStorage.setItem('recentSearches', JSON.stringify(this.recentSearches));
    }

    public setSearch(searchObj) {
      this.criteria = searchObj.criteria;
      this.keyWords = searchObj.keywords;
      this.updateSearchFields(searchObj.keywords, searchObj.criteria);
    }

    showError() {
      console.log("Alert")
      this.toaster.alert({
        title: "Invalid Location",
        message: "Could not find a location that matched the search term",
        icon: "caution",
        theme: "danger",
        position: 'growlTopRight'
      });
    }

    public async search(shouldSave: boolean) {
      let additionalFields = [];
      for (let { field, operator, selection } of this.criteria) {
        let selArea;
        let state;
        if (isAreaSelection(selection)) {
          selArea = selection.radius;
          if (!selection.location) continue;
          if (!this.zipCode) {
            let cities = await this.findCities(selection.location);
            if (!cities.length) return this.showError(); 
            this.zipCode = await this.getZipCodeByLatLng(cities[0].geometry.location);
            if (!this.zipCode) return this.showError();
          }
          state = this.zipCode;
        } else if (isDateSelection(selection)) {
          if (!selection.startPreview || !selection.endPreview) continue;
          state = { startDate: selection.startDate, endDate: selection.endDate };
        } else {
          selArea = "";
          state = selection.map((chip: Chip) => chip.label).join(',');
        }
        if (!state) continue;
        additionalFields.push({
          field: field.name,
          sel: operator.name,
          selArea,
          state
        });

        //return { field: field.name, sel: operator.name, selArea: isAreaSelection(selection) ? selection.radius : "", state: isAreaSelection(selection) ? selection.location : selection.map((chip: Chip) => chip.label).join(',') }
      };
      if (shouldSave) this.saveSearch();
      let searchArr = [{ keyWord: this.keyWords, additionalFields }];

      this.close(searchArr, true);
    }
    private ConfigureColumnsModal() {
    }
    public Configureclose(): void {
        this.modalRef.close('');
    }

    public Configuresave() {
        this.modalRef.close(this.columns);
    }
    public async openCandidate() {
        // const ref = this.aside.open(AddFormSlideout, { record: 123 });
        // /* you can listen to the close event */
        // ref.onClosed.then((result) => {
        //     /* result is the argument sent via the ref */
        //     if (res === 'success') {
        //         /* perfom some action */
        //     }
        // });
        // /* close the slideout from the parent */
        // ref.close('success')
        let settings = await this.httpService.getSettings('bullhornStaffingHost');
        let serverCluster = settings.bullhornStaffingHost;
        let url = `https://${serverCluster}/BullhornStaffing`;
        window.open(
            `${url}/OpenWindow.cfm?id=${this.candidateId}&entity=Candidate&idList=&View=Overview`,
            '_blank'
        );
        // window.open(
        //     'https://app.bullhornstaffing.com/BullhornStaffing/OpenWindow.cfm?id=' + this.candidateId + '&entity=Candidate&idList=&View=Overview',
        //     '_blank'
        // );
        // console.log("Open Candidate", this.AllData)
    }
    public openResume() {
        this.binocularModel = false;

        this.binocularResumeModel = true;
        //this.resumeLink = 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg';
        // console.log("OPEN RESUME");
        // console.log("CANDIDATE ID1:", this.candidateId);
        // console.log("FILE ID1:", this.fileId);
        if (this.fileId) {
            return new Promise(async (resolve, reject) => {
                //this.httpService.lookup(`${this.selectedId}`, 'JobSubmission').then((res) => {
                this.httpService.getFileDetails(this.fileId, this.candidateId).then((res) => {
                    resolve(res);
                    // console.log("RESULT: ", res)
                    // console.log("RESULT: ", res.File.fileContent)
                    // console.log("RESULT: ", res.File.name)
                    // console.log("RESULT: ", res.File.contentType)
                    var blob = this.b64toBlob(res.File.fileContent, res.File.contentType);
                    let a = document.createElement("a");
                    document.body.appendChild(a);
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = String(res.File.name);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    a.remove();
                    this.fileName = res.File.name;
                    this.message = `File Download Successfully `;
                });
            });
        } else {
            this.message = 'No File Available';
        }

    }

    public b64toBlob(b64Data, contentType) {
        contentType = contentType || '';
        let sliceSize = 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }
}
