import { Component } from '@angular/core';

/**
 * @title Long Select 
 */
@Component({
  selector: 'long-select',
  templateUrl: 'long-select.html',
  styleUrls: ['long-select.scss'],
})
export class LongSelect {
  public placeholder: string = 'Select...';
  public states: Array<any> = [
    { label: 'Include All', value: { id: 'INA', label: 'Include All' } },
    { label: 'Include Any', value: { id: 'INAY', label: 'Include Any' } },
    { label: 'Exclude All', value: { id: 'EXC', label: 'Exclude All' } },
    { label: 'Redius', value: { id: 'REDIUS', label: 'Redius' } }
   
  ];
  public sels: Array<any> = [
    { label: '5 miles', value: { id: '5', label: '5 miles' } },
    { label: '10 miles', value: { id: '10', label: '10 miles' } },
    { label: '20 miles', value: { id: '20', label: '20 miles' } },
    { label: '50 miles', value: { id: '50', label: '50 miles' } },
    { label: '100 miles', value: { id: '100', label: '100 miles' } },
  ];
  public state: any ={ id: 'INA', label: 'Include All' };
  public sel: any = { id: '10', label: '10 miles' };
  ;
}