// NG2
import { Component } from '@angular/core';
// Vendor
// APP

@Component({
  selector: 'platform-root',
  template: `
    <router-outlet></router-outlet>
  `
})
export class AppComponent {
}
