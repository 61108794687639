// NG
import { Injectable } from '@angular/core';
// Vendor
import { AppBridge } from 'novo-elements';
import { EntityTypes } from '@bullhorn/bullhorn-types';
// APP
import { AppBridgeService } from '../tools/service/app-bridge.service';
import { SearchResponse } from '../interfaces/bullhorn';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import axios from 'axios';
var Spherical = require('spherical-geometry-js');
import { SearchComponent } from '../search/search.component';

/**
 * Provides simple methods for calling Bullhorn rest endpoints via app bridge.
 */
@Injectable()
export class HttpService {
  private MAX_RECORDS_TO_RETURN = 50000;
  private databaseUrl = environment.databaseUrl;
  constructor(private appBridgeService: AppBridgeService, private https: HttpClient) { }

  /**
   * Handles errors and unwraps nested response from App Bridge
   *
   * The AppBridge returns a response that wraps the server response:
   * {
   *     data: { <http server response> },
   *     error: { <any post-robot errors> }
   * }
   *
   * A null response means that AppBridge did not connect and the call failed.
   *
   * @param response the server response wrapped in a post-robot response from AppBridge
   * @param resolve  the resolve method to call if successful
   * @param reject   the reject method to call if unsuccessful
   */
  private static handleAppBridgeResponse(response: any, resolve: (any) => void, reject: (any) => void): void {
    if (!response) {
      reject(response);
    } else if (response.error) {
      reject(response.error);
    } else if (response.data) {
      resolve(response.data);
    } else {
      resolve(response);
    }
  }
  private async request(method: string, url: string, data?: any) {
    // const token = await this.oktaAuth.getAccessToken();

    const result = this.https.request(method, url, {
      body: data,
      responseType: 'json',
      observe: 'body',
      headers: {
        // Authorization: `Bearer ${token}`
      }
    });
    return new Promise((resolve, reject) => {
      result.subscribe(resolve, reject);
    });
  }

  /**
   * Performs a bullhorn /entity call to get an entity by ID using appBridge
   */
  getEntity(entityType: EntityTypes, entityId: number, fields: string = '*', meta: string = 'off'): Promise<any> {
    return new Promise((resolve, reject) => {
      this.appBridgeService.execute((appBridge: AppBridge) => {
        appBridge.httpGET(`entity/${entityType}/${entityId}?fields=${fields}&layout=RecordEdit&showEditable=true&meta=${meta}`).then((response: any) => {
          HttpService.handleAppBridgeResponse(response, resolve, reject);
        }).catch((error: Error) => {
          reject(error);
        });
      });
    });
  }

  getMeta(entityType: EntityTypes, fields: string = '*'): Promise<any> {
    return new Promise((resolve, reject) => {
      this.appBridgeService.execute((appBridge: AppBridge) => {
        appBridge.httpGET(`meta/${entityType}?fields=${fields}&meta=full`).then((response: any) => {
          HttpService.handleAppBridgeResponse(response, resolve, reject);
        }).catch((error: Error) => {
          reject(error);
        });
      });
    });
  }

  /**
   * Performs a bullhorn /entity insert call to insert an entity using appBridge
   */
  insertEntity(entityType: EntityTypes, body: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.appBridgeService.execute((appBridge: AppBridge) => {
        appBridge.httpPUT(`services/${entityType}`, body).then((response: any) => {
          HttpService.handleAppBridgeResponse(response, resolve, reject);
        }).catch((error: Error) => {
          reject(error);
        });
      });
    });
  }

  /**
   * Performs a bullhorn /entity update call to update an entity using appBridge
   */
  MassUpdate(body: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.appBridgeService.execute((appBridge: AppBridge) => {
        appBridge.httpPOST(`massUpdate/JobSubmission`, body).then((response: any) => {
          HttpService.handleAppBridgeResponse(response, resolve, reject);
        }).catch((error: Error) => {
          reject(error);
        });
      });
    });
  }

  updateEntity(entityType: EntityTypes, entityId: number, body: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.appBridgeService.execute((appBridge: AppBridge) => {
        appBridge.httpPOST(`entity/${entityType}/${entityId}`, body).then((response: any) => {
          HttpService.handleAppBridgeResponse(response, resolve, reject);
        }).catch((error: Error) => {
          reject(error);
        });
      });
    });
  }
  updateservices(entityType: EntityTypes, entityId: any, body: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.appBridgeService.execute((appBridge: AppBridge) => {
        appBridge.httpPOST(`services/${entityType}/${entityId}`, body).then((response: any) => {
          HttpService.handleAppBridgeResponse(response, resolve, reject);
        }).catch((error: Error) => {
          reject(error);
        });
      });
    });
  }

  /**
   * Performs a bullhorn /lookup/expanded lookup call to get entities for the picker
   */
  lookup(query: string, entityType: EntityTypes): Promise<any> {
    return new Promise((resolve, reject) => {
      this.appBridgeService.execute((appBridge: AppBridge) => {
        appBridge.httpGET(`lookup/expanded?count=20&start=0&entity=${entityType}&filter=${query}`).then((response: any) => {
          HttpService.handleAppBridgeResponse(response, resolve, reject);
        }).catch((error: Error) => {
          reject(error);
        });
      });
    });
  }

  /**
   * Performs a bullhorn /file/Candidate getFileDetails call to get filed Details
   */
  getFileDetails(fileId: any, entityId: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.appBridgeService.execute((appBridge: AppBridge) => {
        console.log("appBridge", appBridge);

        appBridge.httpGET(`file/Candidate/${entityId}/${fileId}`).then((response: any) => {
          HttpService.handleAppBridgeResponse(response, resolve, reject);
        }).catch((error: Error) => {
          reject(error);
        });
      });
    });
  }


  /**
   * Returns an object that contains the requested settings as properties
   *
   * @param settings - comma separated list of settings to get from Bullhorn for the current user
   */
  getSettings(settings: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.appBridgeService.execute((appBridge: AppBridge) => {
        appBridge.httpGET(`settings/${settings}`).then((response: any) => {
          HttpService.handleAppBridgeResponse(response, resolve, reject);
        }).catch((error: Error) => {
          reject(error);
        });
      });
    });
  }

  /**
   * Performs a bullhorn /search or /query call through the appBridge.
   *
   * If the entity is indexed using Lucene, then a /search call will be made. If non-indexed, then /query will be used.
   * If there are more records than can be returns (total > count) then makes follow on calls until the count or
   * total has been reached, whichever comes first. If a small count is provided, follow-on calls will not be made.
   */
  search(entityType: EntityTypes,
    query: string,
    fields: string = '*',
    meta: string = 'off',
    count: number = 100,
    sort: string = '-dateAdded',
    override: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.appBridgeService.execute(async (appBridge: AppBridge) => {
        console.log('search executing');
        let postData: any = {};
        let where = query;
        if (override && override == 'query') {
          postData = { where, fields, meta, count, sort, start: 0 };
        } else {
          postData = { query, fields, meta, count, sort, start: 0 };
        }

        const searchEndpoint: string = EntityTypes.isSearchable(entityType.toString()) ? (!override ? 'search' : override) : (!override ? override : 'query');
        console.log('searchEndpoint', searchEndpoint);
        const searchResponse: { data: SearchResponse, error: any } = await appBridge.httpPOST(`${searchEndpoint}/${entityType}`, postData);
        let onePull = searchResponse.data;

        // If the user provided a count that is small, don't make multiple calls
        while (this.shouldPullMoreRecords(onePull, count)) {
          postData.start = onePull.data.length;
          const nextSearchResponse: { data: SearchResponse, error: any } = await appBridge.httpPOST(
            `${searchEndpoint}/${entityType}`, postData).catch(error => reject(error));
          const nextPull = nextSearchResponse.data;
          nextPull.data.push(...onePull.data);
          onePull = nextPull;
        }
        HttpService.handleAppBridgeResponse(onePull, resolve, reject);
      });
    });
  }
  public candidateFields =  [
    'id',
    'firstName',
    'lastName',
    'status',
    'occupation'
 ];
  public sharedFields = [
    this.candidateFields
  ].join(',');
  public shouldPullMoreRecords(searchResponse: SearchResponse, max: number): boolean {
    console.log('searchResponse', searchResponse);
    let total = searchResponse.total;
    let start = searchResponse.start;
    const count = searchResponse.count ? searchResponse.count : 0;
    const maxTotal = Math.min(max, this.MAX_RECORDS_TO_RETURN);
    console.log('maxTotal', maxTotal);

    // Don't pull more if we already have the maximum requested
    if (total && total >= maxTotal) {
      console.log('false 1');
      return false;
    }

    // Handle missing values
    if (start == null) {
      start = 0;
    }
    if (total == null) {
      total = count;
    }

    const nextStart = start + count;
    const nextEnd = Math.min(nextStart + maxTotal, total);
    if (nextStart < total && count !== 0 && nextStart < maxTotal) {
      console.log(`--> Follow On Find(${nextStart} - ${nextEnd})`);
      return true;
    }
    console.log('false 2');
    return false;
  }

  getCoordinates(zipCode) {
    return new Promise (async (resolve, reject) => {
      this.request('GET', `${this.databaseUrl}/coordinates/${zipCode}`).then(resp => {
        resolve(resp);
      })
    });
  }

  getCities(range): Promise<Array<string>> {
    return new Promise (async (resolve, reject) => {
      this.request('POST', `${this.databaseUrl}/cities/`, range).then((resp: Array<string>) => {
        resolve(resp);
      })
    });
  }

  searchProcess(searchArr) {
    console.log('searchArr', searchArr);

    for (var key in searchArr[0].additionalFields) {
      console.log(searchArr[0].additionalFields[key]);
      let radius = searchArr[0].additionalFields[key].selArea;
      var self = this;
      var distanceInMeters = parseFloat(radius) * 1610;
      this.parseAddress(searchArr[0].additionalFields[key].address, searchArr[0].additionalFields[key].state).then(function (coordinates: any) {
        console.log('Received coordinates', coordinates);
        const latlng = new Spherical.LatLng(coordinates.lat, coordinates.lng);

        var regions = [{
          direction: 'north',
          degrees: 0
        }, {
          direction: 'northwest',
          degrees: 315
        }, {
          direction: 'west',
          degrees: 270
        }, {
          direction: 'southwest',
          degrees: 225
        }, {
          direction: 'south',
          degrees: 180
        }, {
          direction: 'southeast',
          degrees: 135
        }, {
          direction: 'east',
          degrees: 90
        }, {
          direction: 'northeast',
          degrees: 45
        }];
        var geoObject: any = {};

        for (var i in regions) {
          geoObject[regions[i].direction] = Spherical.computeOffset(latlng, distanceInMeters, regions[i].degrees);
        }

        var range = {
          "latMax": geoObject.northwest.lat(),
          "latMin": geoObject.southeast.lat(),
          "lngMax": geoObject.northwest.lng(),
          "lngMin": geoObject.southeast.lng()
        };

        console.log('range is', range);

        self.getCities(range).then((cities:any) => {
          let queryParams = ``;
          cities.forEach((city, index) => {
            if (index == cities.length - 1){
              queryParams += `address.city%3A"${city}"`;
            } else {
              queryParams += `address.city%3A"${city}" OR `;
            }
          });

          // fetch modal search filters here and apply to queryParams

          self.appBridgeService.execute(async (appBridge: AppBridge) => {
            const searchResponse: { data: SearchResponse, error: any } = await appBridge.httpGET(`search/Candidate?count=500&fields=${self.sharedFields}&query=status:"New Lead" AND (${queryParams})`);

              let onePull = searchResponse.data;
              let count = 50000;
              let start = 0;

              console.log('onePull initial', onePull);

              // If the user provided a count that is small, don't make multiple calls
              while (self.shouldPullMoreRecords(onePull, count)) {
                start = onePull.data.length;
                const nextSearchResponse: { data: SearchResponse, error: any } = await appBridge.httpGET(
                  `search/Candidate?start=${start}&count=500&fields=${self.sharedFields}&query=status:"New Lead" AND (${queryParams})`).catch(error => console.log(error));
                const nextPull = nextSearchResponse.data;
                nextPull.data.push(...onePull.data);
                onePull = nextPull;
              }

              console.log('onePull', onePull);
              return onePull.data;
              // this.searchComponent.getCandidate(onePull.data).then((results) => {
              //   this.searchComponent.displayTable();
              //   this.searchComponent.buildSharedColumns();
              //   this.searchComponent.loading = false;
              // });
  
            
          });
          
           
          
        });   
  

      });
    }
  }
  parseAddress = function (address, state) {
    address = address+(state!=""?', '+state:'');
    return new Promise(async(resolve, reject) => {
      var url = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + encodeURI(address) + '&key=AIzaSyCkrQZPtfZoTIq-uJQoi-VN_sAjzJ0lwJM';
      console.log('url', url);
      axios.get(encodeURI(url))
      .then(function (response:any) {
        // handle success
        console.log('response', response);
        try {
          var json = response.data;
          if (json.results && json.results.length > 0) {
              var location = json.results[0].geometry.location;
              var coordinates = {
                  lat: location.lat,
                  lng: location.lng
              };
              resolve(coordinates);
          } else {
              reject("Failed to parse location");
          }
        } catch (e) {
            reject(e);
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });

    });
  }
  loadOptions = function(){
    return new Promise(async(resolve, reject) => {
      this.appBridgeService.execute(async (appBridge: AppBridge) => {
        const searchResponse: { data: SearchResponse, error: any } = await appBridge.httpGET(`meta/Candidate?fields=*`);

        let metaData = searchResponse.data;
         
        console.log('metaData initial', metaData);
        resolve(metaData);
          
      });
    });
  }
}
