export const environment = {
  local: false,
  production: false,
  appBridgeConfig: {
    title: 'CustomAppInStaging',
    url: 'https://HOSTED_URL/sample',
    color: 'blue',
  },
  databaseUrl: 'https://candidate-search.cloudgate-api.com'
  // databaseUrl: 'http://localhost:8888'
};
