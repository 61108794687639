export interface Result<T> {
  entity: T;
  success: boolean;
  errors?: Array<string>;
}

export interface Field {
  name: string;
  label: string;
  dataType?: string;
  optionsUrl?: string;
}

export interface Operator {
  name: string;
  label: string;
  dataTypes?: Array<string>;
};

export interface Chip {
  label: string;
  value: string | number;
}

export interface DateSelection {
  startDate: Array<Date>;
  startPreview: string;
  startError?: string | null;
  endDate: Array<Date>;
  endPreview: string;
  endError?: string | null;
  label?: string | null;
}

export type ChipSelection = Array<Chip>;

export interface AreaSelection {
  radius: number;
  location: string;
}

export type Selection = ChipSelection | AreaSelection | DateSelection;

export type Criteria = Array<{ field: Field; operator: Operator; selection: Selection }>;

export const isAreaSelection = (x: Selection): x is AreaSelection => 'radius' in x;

export const isDateSelection = (x: Selection): x is DateSelection => 'startDate' in x;

export const isChipSelection = (x: Selection): x is ChipSelection => !isAreaSelection(x) && !isDateSelection(x);
