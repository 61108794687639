// NG
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// Vendor
import { NovoElementsModule } from 'novo-elements';
import { NovoToastService } from 'novo-elements';
// APP
import { HttpService } from './services/http.service';
import { AppComponent } from './app.component';
import {HttpClientModule} from '@angular/common/http';
import {AppBridgeService} from './tools/service/app-bridge.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SearchComponent } from './search/search.component';
import { BinocularModelComponent } from './binocular-model/binocular-model.component';
import { BasicPicker } from './basic-picker/basic-picker';
import { FormattedChips } from './chips/formatted-chips';
import { LongSelect } from './long-select/long-select';
import { FormsModule } from '@angular/forms';

const routes: Routes = [
  { path: '', redirectTo: '', pathMatch: 'full' },
  { path: 'search', component: SearchComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    SearchComponent,
    BinocularModelComponent,
    // BasicPicker,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, { useHash: true }),
    HttpClientModule,
    NovoElementsModule,
    BrowserAnimationsModule,
    FormsModule
  ],
  providers: [
    AppBridgeService,
    HttpService,
    NovoToastService,
    SearchComponent,
    BasicPicker,
    FormattedChips,
    LongSelect
  ],
  bootstrap: [
    AppComponent
  ],
})
export class AppModule {}
