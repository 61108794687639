import { Component } from '@angular/core';
import { HttpService } from '../services/http.service';

/**
 * @title Basic Picker Example
 */
@Component({
  selector: 'basic-picker',
  templateUrl: 'basic-picker.html',
  styleUrls: ['basic-picker.css'],
})
export class BasicPicker {
  public placeholder: string = 'Select...';
  public formatted: any;
  public value: string;
  public options = [];

  constructor(private httpService: HttpService) {
    const collaborators = [
      {
        name: 1,
        label: 'Brian',
        // lastName: 'Kimball',
      },
      {
        name: 2,
        label: 'Josh',
        // lastName: 'Godi',
      },
      {
        name: 3,
        label: 'Alec',
        // lastName: 'Sibilia',
      },
      {
        name: 4,
        label: 'Kameron',
        // lastName: 'Sween',
      },
    ];

    this.formatted = {
      field: 'name',
      format: `$label`,
      options: [],
    };
    
  }
  // loadData(){
  //   this.httpService.loadOptions().then((res: any) => {
  //     console.log('loadOptions loadData', res);

  //     for (let i = 0; i < res.fields.length; i++) {
  //       //console.log(res.fields[i].label);
  //       if (i != 0 || res.fields[i].hideFromSearch == false)
  //         if (!res.fields[i].label.includes('(Field is Not Searchable)'))
  //           this.options.push({ 'name': res.fields[i].name, 'label': res.fields[i].label });
  //     }
  //     this.formatted = {
  //       field: 'name',
  //       format: `$label`,
  //       options: this.options,
  //     };
  //     return true;
  //   });
  // }
  onChanged(event) {
    console.log('EVENT Sonchanged', event); // tslint:disable-line
    // alert(this.value)
  }
}