import { Component } from '@angular/core';

/**
 * @title Formatted Chips
 */
@Component({
  selector: 'formatted-chips',
  templateUrl: 'formatted-chips.html',
  styleUrls: ['formatted-chips.scss'],
})
export class FormattedChips {
  public formatted: any;
  public placeholder: string = 'Select...';
  public value: any;

  constructor() {
    const collaborators = [
      {
        id: 1,
        firstName: 'Brian',
        lastName: 'Kimball',
        searchEntity: 'candidate',
      },
      {
        id: 2,
        firstName: 'Josh',
        lastName: 'Godi',
        searchEntity: 'contact',
      },
      {
        id: 3,
        firstName: 'Alec',
        lastName: 'Sibilia',
        searchEntity: 'candidate',
      },
      {
        id: 4,
        firstName: 'Kameron',
        lastName: 'Sween',
      },
    ];
    this.formatted = {
      format: '$firstName',
      options: [],
    };
  }

  onChanged(event,a) {
    console.log('EVENT', event); // tslint:disable-line
    //alert(this.value)
    console.log('EVENT a', a);
  }
}