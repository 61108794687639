/* eslint-disable */

import { Component, Inject, OnInit, ViewContainerRef, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormsModule } from '@angular/forms';
// Vendor
import {
  AppBridge,
  FormUtils,
  NovoModalService,
  IDataTableColumn,
  IDataTablePaginationOptions,
  IDataTableSearchOptions,
  NovoDataTable,
  IDataTablePreferences,
} from 'novo-elements';
import { startOfDay, endOfDay, format, addMinutes } from 'date-fns';
import { AppBridgeService } from './../tools/service/app-bridge.service';
import { HttpService } from '../services/http.service';
import { NovoFieldset } from '../interfaces/bullhorn';
import { Subject } from 'rxjs';
import { DOCUMENT, Location } from '@angular/common';
import { BinocularModelComponent } from '../binocular-model/binocular-model.component';
// import { ModalAddFormDemo } from '../search-model/binocular-model.component';
import axios from 'axios';
import { SearchResponse } from '../interfaces/bullhorn';
import { Field, Operator, Selection, Criteria } from '../tools/tools.types'

var Spherical = require('spherical-geometry-js');
const MAX_FIELDS = 200;

@Component({
  selector: 'platform-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  loading = false;
  connected = true;
  isNovoEnabled = false;
  public showTable: boolean;
  zipCode: any;
  radius: any;
  private readonly corpId: number;
  private readonly privateLabelId: number;
  private readonly userId: number;
  private readonly entityId: number;
  private readonly entityType: string;
  // Shared configuration
  public sharedColumns: IDataTableColumn<any>[] = [];
  // public resArr = [];
  //
  public sharedDisplayColumns = [
    'preview',
    'candidateId',
    'firstName',
    'lastName',
    'status',
    'occupation',
    'city'
  ];
  public sharedPaginationOptions: IDataTablePaginationOptions = {
    theme: 'standard',
    pageSize: 10,
    pageSizeOptions: [10, 50, 100, 250, 500],
  };
  public widePaginationOptions: IDataTablePaginationOptions = {
    theme: 'basic-wide',
    pageSize: 10,
    pageSizeOptions: [10, 50, 100, 250, 500],
  };
  public sharedSearchOptions: IDataTableSearchOptions = {
    placeholder: 'Search for things...',
    tooltip: 'HELLO',
  };
  public sharedDefaultSort: { id: string; value: string } = {
    id: 'id',
    value: 'asc',
  };
  public basicRows: any[];
  public selectedRecordId: string = '';
  private staticDataSet1: any[] = [];
  public keywords = '';
  public operators: Array<Operator> = [
      { name: 'INA', label: 'Include All', },
      { name: 'INAY', label: 'Include Any', },
      { name: 'EXC', label: 'Exclude All', },
      { name: 'RADIUS', label: 'Radius', dataTypes: ['Address'] },
      { name: 'WITHIN', label: 'Within', dataTypes: ['Timestamp'] },
      { name: 'BEFORE', label: 'Before', dataTypes: ['Timestamp']},
      { name: 'AFTER', label: 'After', dataTypes: ['Timestamp'] },
      { name: 'EQUALS', label: 'Equals', dataTypes: ['Timestamp'] },
      { name: 'BETWEEN', label: 'Is Between', dataTypes: ['Timestamp'] }
  ];
  public criteria: Criteria = [
    { field: { name: "address", label: "Address", dataType: 'Address' }, operator: { ...this.operators[3] }, selection: { radius: 20, location: "" } },
    { field: { name: "status", label: "Status" }, operator: { ...this.operators[0] }, selection: [] },
    { field: { name: "type", label: "Type" }, operator: { ...this.operators[0] }, selection: [] },
    { field: { name: "occupation", label: "Title" }, operator: { ...this.operators[0] }, selection: [] },
    { field: { name: "dateLastComment", label: "Last Note", dataType: 'Timestamp' }, operator: { ...this.operators[4] }, selection: [] },
    { field: { name: "dateAdded", label: "Date Added", dataType: 'Timestamp' }, operator: { ...this.operators[4] }, selection: [] },
    { field: { name: "companyName", label: "Current Company" }, operator: { ...this.operators[0] }, selection: [] }
  ];
  public recentSearches: Array<{ criteria: Criteria, keywords: string, date: Date }> = [];


  constructor(private appBridgeService: AppBridgeService,
    private modalService: NovoModalService,
    private httpService: HttpService,
    private formUtils: FormUtils,
    private route: ActivatedRoute,
    private view: ViewContainerRef,
    private ref: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: any,
    @Inject(Location) private location: any) {

    this.modalService.parentViewContainer = view; // This is to tell the modal which view to display on top of

    // Get query string parameters passed over from Bullhorn
    this.entityType = this.route.snapshot.queryParamMap.get('EntityType');
    this.entityId = this.getBullhornId('EntityID')
    this.userId = this.getBullhornId('UserID');
    this.corpId = this.getBullhornId('CorporationID');
    this.privateLabelId = this.getBullhornId('PrivateLabelID');
    this.connected = !!this.entityId && !!this.userId && !!this.corpId && !!this.privateLabelId;
  };

  public candidateFields = [
    'id',
    'firstName',
    'lastName',
    'status',
    'occupation',
    'address'
  ];

  public sharedFields = [
    this.candidateFields
  ].join(',');

  public globalSearchEnabled: boolean = true;
  public refreshSubject: Subject<boolean> = new Subject();

  ngOnInit(): void {
    this.getRecentSearches();
    if (this.connected) {
      // this.httpService.search('Candidate', `isDeleted:false'`, this.sharedFields, 'off', 20, 'dateAdded', 'search').then((results) => {
      //   this.getCandidate(results).then((results) => {
      //     this.loading = true;
      //     this.displayTable();
      //     this.buildSharedColumns();
      //     this.loading = false;
      //     this.GetBullhornCandidatesByRadiusZip('33101', 100).then(resp => {
      //       console.log('coordinates resp', resp);
      //     });
      //   });
      // });
    } else {
      console.log('not connected');
    }
  }

  public onPreferencesChanged(event: IDataTablePreferences): void {
    console.log('Preferences changed (persist manually):', event); // tslint:disable-line
  }

  public resized(event): void {
    console.log('Column Width changed (persist manually): ', event); // tslint:disable-line
  }

  public loadDataset(setIndex: number) {
    switch (setIndex) {
      case 1:
        this.basicRows = [...this.staticDataSet1];
        break;
      default:
        break;
    }
  }

  public updateSearchFields(keywords, criteria) {
    this.keywords = keywords;
    this.criteria = criteria;
  }

  getRecentSearches = () => {
    let recentSearchString = localStorage.getItem('recentSearches');
    this.recentSearches = recentSearchString ? JSON.parse(recentSearchString).map(search => {
      search.date = new Date(search.date);
      return search;
    }) : [];
  }

  displayTable = async () => {
    console.log('this.showTable', this.showTable);

    this.showTable = true;
    console.log('this.showTable', this.showTable);

    this.basicRows = [...this.staticDataSet1];
  }

  openCandidate = async (e) => {
    let settings = await this.httpService.getSettings('bullhornStaffingHost');
    let serverCluster = settings.bullhornStaffingHost;
    let url = `https://${serverCluster}/BullhornStaffing`;
    window.open(
      `${url}/OpenWindow.cfm?id=${e.row.candidateId}&entity=Candidate&idList=&View=Overview`,
      '_blank'
    );
  }

  buildSharedColumns = async () => {
    let self = this;
    this.sharedColumns = [
      //{ id: 'preview', type: 'action', enabled: true, handlers: { click: function (event) { self.binocularModel(event) } }, action: { icon: 'preview', }, },
      { id: 'preview', type: 'action', enabled: true, handlers: { click: this.openCandidate }, action: { icon: 'preview', }, },
      // {id: 'preview', type: 'action', enabled: true, handlers: { click: function (event) { self.searchModel(event) } },action: { icon: 'preview', },},
      { id: 'candidateId', label: 'Id', enabled: true, type: 'text', filterable: true, sortable: true, },
      { id: 'firstName', label: 'First Name', enabled: true, type: 'text', filterable: true, sortable: true, },
      { id: 'lastName', label: 'Last Name', enabled: true, type: 'text', filterable: true, sortable: true, },
      { id: 'status', label: 'Status', enabled: true, type: 'text', filterable: true, sortable: true, },
      { id: 'occupation', label: 'Title', enabled: true, type: 'text', filterable: true, sortable: true, },
      { id: 'city', label: "City", enabled: true, type: 'text', filterable: true, sortable: true }
    ]
  }

  public preview(event: { originalEvent: MouseEvent; row: any }): void {
    this.selectedRecordId = event.row.id.toString();
  }

  private getCandidate(candidates) {
    return new Promise(async (resolve, reject) => {
      (async () => {
        let self = this;
        this.staticDataSet1 = [];
        candidates.forEach(candidate => {
          this.staticDataSet1.push({
            candidateId: candidate.id,
            firstName: candidate.firstName,
            lastName: candidate.lastName,
            status: candidate.status,
            occupation: candidate.occupation,
            city: candidate.address.city
          });
        });
        console.log('staticDataSet1', this.staticDataSet1);

        // ignore
        resolve(true);
      })();
    });
  }


  private getBullhornId(param: string): number {
    return parseInt(this.route.snapshot.queryParamMap.get(param), 10);
  }

  binocularModel(event) {
    console.log("test data1", event.row);
    const modalRef = this.modalService.open(BinocularModelComponent, {
      modaltype: 1,
      selectedValues: event.row,
      entityType: "binocularmodelpopup"
    });
  }
  searchModel(event) {
    //console.log("test data1", event.row);
    this.getRecentSearches();
    console.log({ criteria: this.criteria, keywords: this.keywords });
    const modalRef = this.modalService.open(BinocularModelComponent, {
      modaltype: 1,
      selectedValues: event.row,
      entityType: "searchmodelpopup",
      updateSearchFields: this.updateSearchFields.bind(this),
      criteria: this.criteria,
      keywords: this.keywords,
      recentSearches: this.recentSearches,
      operators: this.operators
    });
    modalRef.onClosed.then((result) => {
      // alert(result)
      if (result != '') {
        this.searchProcess(result);
      }
    });
  }

  GetLatLngFromZip = async (zipCode) => {
    return new Promise(async (resolve, reject) => {
      let coordinates = await this.httpService.getCoordinates(zipCode);
      resolve(coordinates);
    });
  };

  GetBullhornCandidatesByRadiusZip(zip, radius) {
    if (!zip || !radius) return;
    // return new Promise(async(resolve, reject) => {
    this.loading = true;
    // let zip = this.zipCode;
    // let radius = this.radius;

    var self = this;
    var distanceInMeters = parseFloat(radius) * 1610;

    var parseZip = function (zip) {
      return new Promise(async (resolve, reject) => {
        var url = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + encodeURI(zip) + '&key=AIzaSyCkrQZPtfZoTIq-uJQoi-VN_sAjzJ0lwJM';

        axios.get(encodeURI(url))
          .then(function (response: any) {
            // handle success
            console.log('response', response);
            try {
              var json = response.data;
              if (json.results && json.results.length > 0) {
                var location = json.results[0].geometry.location;
                var coordinates = {
                  lat: location.lat,
                  lng: location.lng
                };
                resolve(coordinates);
              } else {
                reject("Failed to parse location");
              }
            } catch (e) {
              reject(e);
            }
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
          .finally(function () {
            // always executed
          });

      });
    };

    parseZip(zip).then(function (coordinates: any) {
      // old distance 80467
      console.log('Received coordinates', coordinates);
      //const latlng = new spherical.LatLng(28.221569792307694, -81.69681930769234);
      const latlng = new Spherical.LatLng(coordinates.lat, coordinates.lng);

      var regions = [{
        direction: 'north',
        degrees: 0
      }, {
        direction: 'northwest',
        degrees: 315
      }, {
        direction: 'west',
        degrees: 270
      }, {
        direction: 'southwest',
        degrees: 225
      }, {
        direction: 'south',
        degrees: 180
      }, {
        direction: 'southeast',
        degrees: 135
      }, {
        direction: 'east',
        degrees: 90
      }, {
        direction: 'northeast',
        degrees: 45
      }];
      var geoObject: any = {};

      for (var i in regions) {
        geoObject[regions[i].direction] = Spherical.computeOffset(latlng, distanceInMeters, regions[i].degrees);
      }

      //var query = {
      //    "params": {
      //        "type": "search",
      //        "entity": "Candidate",
      //        "queryParams": [{
      //            "isGeocoder": true,
      //            "latOne": geoObject.northwest.lat(),
      //            "latTwo": geoObject.southeast.lat(),
      //            "lngOne": geoObject.northwest.lng(),
      //            "lngTwo": geoObject.southeast.lng()
      //        }]
      //    }
      //};

      var range = {
        "latMax": geoObject.northwest.lat(),
        "latMin": geoObject.southeast.lat(),
        "lngMax": geoObject.northwest.lng(),
        "lngMin": geoObject.southeast.lng()
      };

      console.log('range is', range);

      self.httpService.getCities(range).then((cities: any) => {
        const locationQueryList = [];

        cities = cities.reduce((acc, cur) => {
          if (!acc.includes(cur)) acc.push(cur);
          return acc;
        }, []);

        for (let i = 0; i < Math.ceil(cities.length / MAX_FIELDS); i++) {
          const citySlice = cities.slice(i * MAX_FIELDS, (i + 1) * MAX_FIELDS);
          const locationQuery = citySlice.map(city => `address.city%3A"${city}"`).join(' OR ');
          locationQueryList.push(locationQuery)
        }

        // fetch modal search filters here and apply to queryParams

        self.appBridgeService.execute(async (appBridge: AppBridge) => {
          const data = [];
          for (const locationQuery of locationQueryList) {
            const searchResponse: { data: SearchResponse, error: any } = await appBridge.httpGET(`search/Candidate?count=500&fields=${self.sharedFields}&query=(${locationQuery})`);

            // appBridge.httpGET(`search/Candidate?count=500&fields=${self.sharedFields}&query=status:"New Lead" AND (${queryParams})`).then((results: any) => {
            let onePull = searchResponse.data;
            let count = 50000;
            let start = 0;

            console.log('onePull initial', onePull);

            // If the user provided a count that is small, don't make multiple calls
            while (self.httpService.shouldPullMoreRecords(onePull, count)) {
              start = onePull.data.length;
              const nextSearchResponse: { data: SearchResponse, error: any } = await appBridge.httpGET(
                `search/Candidate?start=${start}&count=500&fields=${self.sharedFields}&query=(${locationQuery})`).catch(error => console.log(error));
              const nextPull = nextSearchResponse.data;
              nextPull.data.push(...onePull.data);
              onePull = nextPull;
            }
            data.push(...onePull.data)
            console.log('onePull', onePull);
          }

          self.getCandidate(data).then((results) => {
            self.displayTable();
            self.buildSharedColumns();
            self.loading = false;
          });

          // }).catch((error: Error) => {
          //   // reject(error);
          // }); 
        });

        // self.httpService.search('Candidate', queryParams, self.sharedFields, 'off', 20, 'dateAdded', 'search').then((results) => {
        //   console.log('Lat lng search results', results);
        //   self.loading = false;
        // }); 

      });

      // resolve(resp);

      //Modules.Bullhorn.API.query(query).then(function (candidates) {
      //    console.log(Modules.System,'queried', candidates);
      //});

    }).catch((err) => {
      console.log(err);
      // resolve(err);
    });
    // });
  };

  private async onRegistered(isRegistered) {
    if (isRegistered) {
      this.connected = true;
      document.body.className = this.isNovoEnabled ? 'novo' : 's-release';
    } else {
      this.connected = false;
    }
  }
  parseAddress = function (field, state) {
    let address = state;
    return new Promise(async (resolve, reject) => {
      var url = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + encodeURI(address) + '&key=AIzaSyCkrQZPtfZoTIq-uJQoi-VN_sAjzJ0lwJM';
      console.log('url', url);
      axios.get(url)
        .then(function (response: any) {
          // handle success
          console.log('response', response);
          try {
            var json = response.data;
            if (json.results && json.results.length > 0) {
              var location = json.results[0].geometry.location;
              var coordinates = {
                lat: location.lat,
                lng: location.lng
              };
              resolve(coordinates);
            } else {
              reject("Failed to parse location");
            }
          } catch (e) {
            reject(e);
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .finally(function () {
          // always executed
        });

    });
  }

  async getCities(address, radius): Promise<Array<string>> {
    var self = this;
    var distanceInMeters = parseFloat(radius) * 1610;

    var url = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + encodeURI(address) + '&key=AIzaSyCkrQZPtfZoTIq-uJQoi-VN_sAjzJ0lwJM';
    try {
      var response = await axios.get(encodeURI(url));
      var json = response.data;
      if (json.results && json.results.length > 0) {
        var location = json.results[0].geometry.location;
        var coordinates = {
          lat: location.lat,
          lng: location.lng
        };
      }

      const latlng = new Spherical.LatLng(coordinates.lat, coordinates.lng);

      var regions = [{
        direction: 'north',
        degrees: 0
      }, {
        direction: 'northwest',
        degrees: 315
      }, {
        direction: 'west',
        degrees: 270
      }, {
        direction: 'southwest',
        degrees: 225
      }, {
        direction: 'south',
        degrees: 180
      }, {
        direction: 'southeast',
        degrees: 135
      }, {
        direction: 'east',
        degrees: 90
      }, {
        direction: 'northeast',
        degrees: 45
      }];

      var geoObject: any = {};

      for (var i in regions) {
        geoObject[regions[i].direction] = Spherical.computeOffset(latlng, distanceInMeters, regions[i].degrees);
      }

      var range = {
        "latMax": geoObject.northwest.lat(),
        "latMin": geoObject.southeast.lat(),
        "lngMax": geoObject.northwest.lng(),
        "lngMin": geoObject.southeast.lng()
      };

      console.log('range is', range);

      var cities: Array<string> = await self.httpService.getCities(range);
      cities = cities.reduce((acc: Array<string>, cur: string): Array<string> => {
        if (acc.includes(cur)) return acc;
        acc.push(cur);
        return acc;
      }, [])
      console.log(cities);
      return cities;
    } catch (err) {
      console.log('Failed to parse zip');
    }
  }

  async searchProcess(searchArr) {
    console.log(searchArr);
    this.loading = true;
    var self = this;
    let searchInfo = searchArr[0];
    let fieldArray = [];
    const { addressFields, timestampFields, otherFields } = searchInfo.additionalFields.reduce((acc, additionalField) => {
      if (additionalField.field === 'address' && additionalField.sel === 'RADIUS') acc.addressFields.push(additionalField);
      else if (additionalField.state?.startDate) {
        acc.timestampFields.push(additionalField);
      }
      else if (additionalField.field && additionalField.sel) acc.otherFields.push(additionalField);
      return acc;
    }, { addressFields: [], timestampFields: [], otherFields: [] });
    //If a zipcode and radius weren't specified in the modal, and were in the outerfields use those
    if (!addressFields.length && this.zipCode && this.radius) {
      addressFields.push({ state: this.zipCode, selArea: this.radius });
    }
    const cities = [];
    let totalCities = 0;
    for (let field of addressFields) {
      let results = await this.getCities(field.state, field.selArea);
      cities.push(...results);
      totalCities += results.length;
    }
    for (let field of timestampFields) {
      let { startDate, endDate } = field.state;
      startDate = startOfDay(startDate);
      endDate = endOfDay(endDate);

      startDate = new Date(startDate.valueOf() + startDate.getTimezoneOffset() * 60 * 1000);
      endDate = new Date(endDate.valueOf() + endDate.getTimezoneOffset() * 60 * 1000);
      let dateFormat = 'YYYYMMDDHHmmss'
      fieldArray.push(`${field.field}: [${format(startDate, dateFormat)} TO ${format(endDate, dateFormat)}]`);
    }
    for (let additionalField of otherFields) {
      if (additionalField.field === 'businessSectors') {
        fieldArray.push(`${additionalField.sel === 'EXC' ? 'NOT ' : ''}businessSectors.id:"^${additionalField.state.split(',').map((value: string)=> `name LIKE '${value}'`).join(additionalField.sel === "INA" ? ' AND ' : ' OR ')}"`);
        continue;
      }

      let fieldString = `${additionalField.sel === 'EXC' ? 'NOT ' : ''}${additionalField.field}:(`;
      let valueArray = [];
      for (let value of additionalField.state.split(',')) {
        let valueString = additionalField.sel === 'INA' ? '+' : '';
        valueString += value.includes(' ') ? `"${value.toLowerCase()}"` : value.toLowerCase();
        valueArray.push(valueString);
      }
      fieldString += `${valueArray.join(' ')})`;
      fieldArray.push(fieldString);
    }
    fieldArray.unshift(`isDeleted:0`)
    let { keyWord } = searchInfo;
    let keyWordQuery = '';
    if (keyWord != "") {
      const regex = /,|(\(|\)|AND|OR)/i;
      let splitList = keyWord.split(regex).filter(_ => _ && _.trim());
      let keywordFields = splitList.map(keyword => {
        keyword = keyword.trim().replace(/"|'/g, '');
        if (regex.test(keyword)) return keyword;
        return `(id%3A"${keyword}" name%3A"${keyword}" companyName%3A"${keyword}" type%3A"${keyword}" occupation%3A"${keyword}" description%3A"${keyword}" fileAttachments.description%3A"${keyword}")`;
      });

      keyWordQuery = `AND (${keywordFields.join(' ')})`
    }
    //@TODO: Maybe add back 'AND NOT status:Archive'
    let maxCities = MAX_FIELDS - fieldArray.length - keyWord.split(',').length;
    if (addressFields.length && maxCities < 1) {
      console.log('Too many fields');
      self.loading = false;
      return;
    }
    let cityFields = cities.map(city => `(address.city:"${city}")`);

    self.appBridgeService.execute(async (appBridge: AppBridge) => {
      const data = [];
      let loopsRequiredForAllCities = Math.ceil(cityFields.length / maxCities) || 1;
      for (let i = 0; i < loopsRequiredForAllCities; i++) {
        let fields = [...fieldArray];
        if (cityFields.length) {
          fields.push(`(${cityFields.slice(i * maxCities, (i + 1) * maxCities)})`);
         }
        let queryParams = encodeURIComponent(fields.join(' AND '));
        let searchResponse: { data: SearchResponse, error: any } = await appBridge.httpGET(`search/Candidate?count=500&fields=${self.sharedFields}&query=${queryParams} ${keyWordQuery}`);
        let tempData = searchResponse.data.data;
        while (self.httpService.shouldPullMoreRecords(searchResponse.data, 50_000)) {
          searchResponse = await appBridge.httpGET(`search/Candidate?start=${tempData.length}&count=500&fields=${self.sharedFields}&query=${queryParams} ${keyWordQuery}`);
          tempData.push(...searchResponse.data.data);
        }
        data.push(...tempData);
      }

      const candidateCities = data.reduce((acc, cur) => {
        if (acc.includes(cur.address.city)) return acc;
        acc.push(cur.address.city);
        return acc;
      }, [])

      console.log({ candidateCities });

      self.getCandidate(data).then((results) => {
        console.log('results', results);
        self.displayTable();
        self.buildSharedColumns();
        self.loading = false;
      });
    });
  }
  

  async searchProcess2(searchArr) {
    this.loading = true;
    var self = this;
    let resArr = [];
    console.log('searchArr', searchArr);
    //this.GetBullhornCandidatesByRadiusZip('10001','10')
    let keyWord = searchArr[0].keyWord;
    for (var key in searchArr[0].additionalFields) {
      console.log(searchArr[0].additionalFields[key]);
      if (searchArr[0].additionalFields[key].field == 'address') {
        let radius = searchArr[0].additionalFields[key].selArea;
        var distanceInMeters = parseFloat(radius) * 1610;
        this.parseAddress(searchArr[0].additionalFields[key].field, searchArr[0].additionalFields[key].state).then(function (coordinates: any) {

          // old distance 80467
          console.log('Received coordinates', coordinates);
          //const latlng = new spherical.LatLng(28.221569792307694, -81.69681930769234);
          const latlng = new Spherical.LatLng(coordinates.lat, coordinates.lng);

          var regions = [{
            direction: 'north',
            degrees: 0
          }, {
            direction: 'northwest',
            degrees: 315
          }, {
            direction: 'west',
            degrees: 270
          }, {
            direction: 'southwest',
            degrees: 225
          }, {
            direction: 'south',
            degrees: 180
          }, {
            direction: 'southeast',
            degrees: 135
          }, {
            direction: 'east',
            degrees: 90
          }, {
            direction: 'northeast',
            degrees: 45
          }];
          var geoObject: any = {};

          for (var i in regions) {
            geoObject[regions[i].direction] = Spherical.computeOffset(latlng, distanceInMeters, regions[i].degrees);
          }



          var range = {
            "latMax": geoObject.northwest.lat(),
            "latMin": geoObject.southeast.lat(),
            "lngMax": geoObject.northwest.lng(),
            "lngMin": geoObject.southeast.lng()
          };

          console.log('range is', range);

          self.httpService.getCities(range).then((cities: any) => {
            let queryParams = ``;
            cities.forEach((city, index) => {
              if (index == cities.length - 1) {
                queryParams += `address.city%3A"${city}"`;
              } else {
                queryParams += `address.city%3A"${city}" OR `;
              }
            });

            // fetch modal search filters here and apply to queryParams
            let keyWordQuery = '';
            if (keyWord != "") {
              keyWordQuery = ` OR ((address.address1%3A'${keyWord}') OR (address.address2%3A'${keyWord}') OR (address.city%3A'${keyWord}') OR (address.state%3A'${keyWord}') OR (address.zip%3A'${keyWord}'))`
            }
            console.log('keywordquery', keyWordQuery);
            self.appBridgeService.execute(async (appBridge: AppBridge) => {
              const searchResponse: { data: SearchResponse, error: any } = await appBridge.httpGET(`search/Candidate?count=500&fields=${self.sharedFields}&query=status:"New Lead" AND (${queryParams}) ${keyWordQuery}`);

              // appBridge.httpGET(`search/Candidate?count=500&fields=${self.sharedFields}&query=status:"New Lead" AND (${queryParams})`).then((results: any) => {
              let onePull = searchResponse.data;
              let count = 50000;
              let start = 0;

              console.log('onePull initial', onePull);


              // If the user provided a count that is small, don't make multiple calls
              while (self.httpService.shouldPullMoreRecords(onePull, count)) {
                start = onePull.data.length;
                var urlk = `search/Candidate?start=${start}&count=500&fields=${self.sharedFields}&query=status:"New Lead" AND (${queryParams}) ${keyWordQuery} `;
                console.log('urlk', urlk);

                const nextSearchResponse: { data: SearchResponse, error: any } = await appBridge.httpGET(urlk).catch(error => console.log(error));
                const nextPull = nextSearchResponse.data;
                nextPull.data.push(...onePull.data);
                onePull = nextPull;
              }

              console.log('onePull', onePull);
              resArr.push(...onePull.data);
              console.log('resArr', resArr);


              self.getCandidate(resArr).then((results) => {
                console.log('results', results);
                self.displayTable();
                self.buildSharedColumns();
                self.loading = false;
              });

              // }).catch((error: Error) => {
              //   // reject(error);
              // }); 
            });



          });



        }).catch((err) => {
          console.log(err);
          alert(err);
          self.loading = false;
        });
      } else {
        let cities = [];
        if (this.zipCode && this.radius) {
          console.log('Getting cities')
          cities = await this.getCities(this.zipCode, this.radius);
        }
        console.log('datacc',searchArr[0].additionalFields[key]);
        
        if (searchArr[0].additionalFields[key].selArea == "") {
          let locationQuery = cities.length ? `AND (${cities.map(city => `(address.city%3A"${city}")`).join(' ')})` : '';
          let field = searchArr[0].additionalFields[key].field;
          let sel = searchArr[0].additionalFields[key].sel;
          let qdata = searchArr[0].additionalFields[key].state || '';
          console.log(qdata)
          let qdataArray = qdata.split(",");
          // qdataArray.splice(-1);
          // Keywords should not try to use field from the additionalFields
          let queryParams = qdataArray.length ? `AND ` : '';
          qdataArray.forEach((value, index) => {
            console.log({ value })
            if (sel == 'INA') {
              if (index == qdataArray.length - 1) {
                queryParams += `${field}%3A"${value}"`;
              } else {
                queryParams += `${field}%3A"${value}" AND `;
              }
            } else if (sel == 'INAY') {
              if (index == qdataArray.length - 1) {
                queryParams += `${field}%3A"${value}"`;
              } else {
                queryParams += `${field}%3A"${value}" OR `;
              }
            } else if (sel == 'EXC') {
              if (index == qdataArray.length - 1) {
                queryParams += `NOT ${field}%3A"${value}"`;
              } else {
                queryParams += `NOT ${field}%3A"${value}" AND `;
              }
            }
          });

          // fetch modal search filters here and apply to queryParams
          let keyWordQuery = '';
          if (keyWord != "") {
            let keywordFields = keyWord.split(',').map((keyword: string) => {
              keyword = keyword.trim();
              return `(id%3A"${keyword}" name%3A"${keyword}" companyName%3A"${keyword}" type%3A"${keyword}" occupation%3A"${keyword}" description%3A"${keyword}" fileAttachments.description%3A"${keyword}")`
            });
            keyWordQuery = `AND (${keywordFields.join(' ')})`
          }
          console.log({ locationQuery, queryParams, keyWordQuery })
          self.appBridgeService.execute(async (appBridge: AppBridge) => {
            const searchResponse: { data: SearchResponse, error: any } = await appBridge.httpGET(`search/Candidate?count=500&fields=${self.sharedFields}&query=status:"New Lead" ${locationQuery} ${queryParams} ${keyWordQuery}`);

            // appBridge.httpGET(`search/Candidate?count=500&fields=${self.sharedFields}&query=status:"New Lead" AND (${queryParams})`).then((results: any) => {
            let onePull = searchResponse.data;
            let count = 50000;
            let start = 0;

            console.log('onePull initial', onePull);


            // If the user provided a count that is small, don't make multiple calls
            while (self.httpService.shouldPullMoreRecords(onePull, count)) {
              start = onePull.data.length;
              var urlk = `search/Candidate?start=${start}&count=500&fields=${self.sharedFields}&query=status:"New Lead" ${locationQuery} ${queryParams} ${keyWordQuery}`;
              console.log('urlk', urlk);

              const nextSearchResponse: { data: SearchResponse, error: any } = await appBridge.httpGET(urlk).catch(error => console.log(error));
              const nextPull = nextSearchResponse.data;
              nextPull.data.push(...onePull.data);
              onePull = nextPull;
            }

            console.log('onePull', onePull);
            resArr.push(...onePull.data);
            console.log('resArr', resArr);


            self.getCandidate(resArr).then((results) => {
              console.log('results', results);
              self.displayTable();
              self.buildSharedColumns();
              self.loading = false;
            });

            // }).catch((error: Error) => {
            //   // reject(error);
            // }); 
          });
        }
      }
    }

    // console.log('resArr',this.resArr);

  }

}
